<template>
  <v-form autocomplete="off" @submit.prevent="createMergedAnalyst">
    <experts-doubles-search
      :value="analyst"
      label="Правильный аналитик"
      @change="updateMergedAnalyst({ field: 'recipientId', value: $event })"
    />

    <experts-doubles-search
      class="mt-2"
      :value="analyst"
      label="Дубль"
      @change="updateMergedAnalyst({ field: 'donorId', value: $event })"
    />

    <div class="mt-4">
      <v-btn type="submit" color="primary mr-4" :disabled="disabledBtn" :loading="loading"> Объединить </v-btn>
    </div>
  </v-form>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import ExpertsDoublesSearch from "./search.vue";

const defaultMergedAnalyst = {
  recipientId: "",
  donorId: ""
};

export default {
  data() {
    return {
      loading: false,
      analyst: { name: "" },

      mergedAnalyst: { ...defaultMergedAnalyst }
    };
  },
  computed: {
    createMergedAnalystDto() {
      return { ...this.mergedAnalyst };
    },
    disabledBtn() {
      return this.mergedAnalyst.donorId === "" || this.mergedAnalyst.recipientId === "";
    }
  },
  methods: {
    updateMergedAnalyst(value) {
      this.mergedAnalyst[value.field] = value.value.id;
    },

    async createMergedAnalyst() {
      this.loading = true;

      try {
        await this.$axios.$post(`analysts/united`, this.createMergedAnalystDto);

        await this.$parent.refreshTable();

        this.clear();

        this.$snackbar.success("Аналитики объединены");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.createMergedAnalystDto)).toHtml();

        this.$snackbar.error("Не удалось объединить аналитиков: " + html);
      } finally {
        this.loading = false;
      }
    },

    clear() {
      this.mergedAnalyst = { ...defaultMergedAnalyst };

      this.analyst = {};
    }
  },
  components: {
    ExpertsDoublesSearch
  }
};
</script>
