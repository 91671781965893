<template>
  <div>
    <h2 class="mb-4">Дубли</h2>

    <data-table ref="dataTable" url="analysts/united" sort-by="unitedAt" :sort-desc="true" :headers="headers">
      <template v-slot:[`item.unitedAt`]="{ item }">
        {{ item.unitedAt | date }}
      </template>
    </data-table>

    <v-divider class="my-6" />

    <div class="text-h6 mb-3">Добавление новой "пары"</div>

    <experts-doubles-add />
  </div>
</template>

<script>
import ExpertsDoublesAdd from "~/components/experts/doubles/add";

export default {
  data() {
    return {
      headers: [
        { text: "Аналитик", value: "recipientName", sortable: true },
        { text: "Дубль аналитика", value: "donorName", sortable: true },
        { text: "Дата добавления ", value: "unitedAt", sortable: true }
      ]
    };
  },
  methods: {
    refreshTable() {
      return this.$refs.dataTable.update();
    }
  },
  components: {
    ExpertsDoublesAdd
  }
};
</script>
