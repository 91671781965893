<template>
  <v-select
    v-model="currentValue"
    :items="analysts"
    item-value="id"
    item-text="name"
    :label="label"
    return-object
    searchable
    clearable
  >
    <template v-slot:no-data>
      <div v-if="loading" class="pa-3">Загрузка...</div>
    </template>

    <template v-slot:item="{ item }">
      <div class="pt-1 pb-1">
        <div>
          {{ item.name }}
        </div>

        <div class="text-subtitle-2 grey--text">
          {{ item.firmName }}
        </div>
      </div>
    </template>

    <template v-slot:prepend-item>
      <div class="pa-3" style="position: sticky; top: 0; background-color: #fff; z-index: 2">
        <div class="mb-3">
          <v-text-field v-model="analyst.name" label="Имя аналитика" clearable hide-details @input="fetchAnalysts" />
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      analysts: [],
      loading: false,

      analyst: {
        name: ""
      }
    };
  },

  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(analyst) {
        this.$emit("change", analyst);

        this.analyst.name = "";
      }
    }
  },
  watch: {
    value: {
      handler() {
        if (Object.keys(this.value).length === 0) {
          this.analyst.name = "";

          this.analysts = [];
        }
      },
      deep: true
    }
  },
  methods: {
    async fetchAnalysts(value) {
      if (!value) {
        this.analysts = [];

        return;
      }

      try {
        this.loading = true;

        this.analysts = await this.$axios.$get(`analysts/twin-search`, {
          params: { analystName: this.analyst.name }
        });
      } catch (e) {
        this.$snackbar.error("Не удалось найти аналитика");

        this.analyst = [];
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
